footer {
  position:absolute;
  bottom:0;
  width:100%;
  height: 200px;
}
.footer {
  background-color: #1A1A1A;
  min-height: 200px;
  color: #bbb;
  padding: 50px 0 20px 20px;
  background-position:  right;
  background-repeat: no-repeat;
  font-family: 'Lato', sans-serif;
  line-height: 24px;
  margin-top: 50px;
}
.webp .footer {
    background-image: url(/images/footer.webp);
}
.no-webp .footer {
    background-image: url(/images/footer.png);
}
.footer-header {
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}
.footer-header .glyphicon { margin-right: 5px; }

.footer ul { list-style: none; margin: 0; padding: 0; }
.footer ul li { margin: 0; padding: 0; }
.footer a { color: #bbb; }

@media (max-width: 750px) {
  .footer { background-position: bottom right; }
  .footer ul { margin-bottom: 25px; }
  .abc { display: none; }
  .footer .row { margin-left: 0; margin-right: 0; }
  .category-header { padding-left: 15px; padding-right: 15px; }
}
