.details { margin-top: 50px; font-family: 'Lato', sans-serif; }
/*.details_text { margin-top: 50px; }*/

/*.details-map {
  /*background-image: url(/images/maps.jpg);
  background-position: top right;*/
  /*height: 300px;
  border-left: 1px solid #cccccc;
}*/

.details-container {
  border-top: 3px solid $blue;
  min-height: 350px;
}
.details-block {
  background-color: #EEEEEE;
  height: 300px;
  padding: 10px;
}
.details-block-center {
  border-width: 0 1px;
  border-style: solid;
  border-color: #cccccc;
}

.details-logo { vertical-align: middle; }
.details-logo img { margin: auto; }

.details-text-border { border-left: 3px solid $blue; padding-left: 15px; }


/* temp */
.col-wrapper {
  border-top: 3px solid #3366cc; position:relative;
}
.col-left {
  width: 50%; float: left; background-color: #DDDDDD;
}
.col-right {
  width: 50%;
  margin-left: 50%;
  position: absolute;
  float: right;
  height: 100%;
  /*background-image: url(/images/maps.jpg);
  background-position: top right;*/
}
.col-container {
  padding: 10px;
}
.col-container-border { border-left: 1px solid #CCCCCC; }

.details-col .glyphicon {
  font-size: 36px; color: #3366CC;
}

.hr-down {
  width: 100%; height: 10px; border: 2px solid #3366CC; border-bottom: medium none; border-radius: 20px 20px 0 0;
  margin: 20px 0 20px;
}
.hr-up {
  width: 100%; height: 10px; border: 2px solid #3366CC; border-top: medium none; border-radius: 0 0 20px 20px;
  margin: 10px 0 40px;
}

.details-map {
  margin-top: 30px;
}
.details-map #map {
  height: 250px;
}

.businesshours td {
    padding-right: 2px;
}


@media (max-width: 750px) {
    .details-col { padding: 10px 0; border-bottom: 1px solid $blue; }
    .details-col-noborder { border-bottom: medium none; }
    .xs-padding, .xd-map { margin: 0 20px; }

    .phone-list a, .web-list a {
        padding-top: 4px;
        padding-bottom: 4px;
    }
}

.details-col .icon-small.glyphicon {
    color: inherit;
    font-size: inherit;
}

.socials a {
    text-decoration: inherit;
}
.socials svg {
    color: $blue;
    height: 30px;
    width: 30px;
}
.socials a:hover svg {
    color: #9ca3af;
}
