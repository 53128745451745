.navbar { border: medium none; }
.navbar-blue { background-color: #3366CC; width: 50%; float: left; text-align: right; padding: 20px 0 5px; }
.navbar-white { background-color: #ffffff; width: 50%; float: right; text-align: left; padding: 20px 0 5px; }
.navbar-blue a, .navbar-white a {
  padding: 0 20px;
  font-size: 20px;
  font-family: 'Lato', sans-serif;
}
.navbar-blue img { margin-left: 30px; }
.navbar-white img { margin-right: 30px; }
.navbar-blue a { color: #ffffff; }
.navbar-white a { color: $blue; }

.searchbar { height: 200px; }
.searchlayer {
  margin: 120px 150px 0 150px;
  background-color: $blue;
  padding: 7px 7px;
  border-radius: 5px;
}

form { margin: 0; padding: 0; }

@media (max-width:768px) {
  .searchbar { height: auto; }
  .searchlayer { margin: 0; }
  .feature-box { margin: 25px 0;}
  .container { width: 100%; }

  .navbar { margin-bottom: 0; }
  .navbar-blue a { display: none; }
  .navbar-white a { display: none; }
}