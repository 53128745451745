.infobox {
  border-top: 3px solid $blue;
  min-height: 120px;
  font-family: 'Lato', sans-serif;
  margin: 50px 0 0;
}
.infobox > div:hover {
  background-color: #DDDDDD;
}


.infobox > .col-md-4 {
  height: 100%;
  background-color: #EEEEEE;
}
.infobox .info-center {
  border-width: 0 1px;
  border-style: solid;
  border-color: #cccccc;
}

.info-icon {
  width: 20%;
  font-size: 36px;
  float: left;
  padding: 10px 0;
  text-align: center;
}
.info-content {
  padding: 10px 10px 20px;
  width: 80%;
  float: left;
}
.info-button { position: absolute; bottom: -15px; left: 40px; }

.feature-box {
  padding-left: 5px;
  padding-right: 5px;
}
.feature-container {
  border: 4px solid #cccccc;
  border-radius: 5px;
  background-color: #EEEEEE;
}
.feature-headline {
  display: block;
  text-align: center;
  font-weight: bold;
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  color: $blue;
  padding: 10px 0;
}
.feature-box p {
  padding-left: 10px;
  padding-right: 10px;
}

.infobox a{
  color: #000000;
  font-family: 'Lato', sans-serif;
  font-size: 18px;
}

.feature-container:hover {
  background-color: #DDDDDD;
  cursor: pointer;
}

.feature-img {
  height: 125px; border-bottom: 2px solid #1a1a1a;
}

@media (max-width: 750px) {
    .infobox { margin: 10px; border-style: solid; border-width: 0 1px; border-color: #cccccc; border-top: 3px solid $blue; }
    .infobox > .col-md-4 { border-bottom: 2px solid $blue; }

    .category-item { padding-top: 5px; padding-bottom: 5px; }
}
