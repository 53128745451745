/* open-sans-italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: url('/fonts/open-sans-v17-latin-italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Italic'), local('OpenSans-Italic'),
    url('/fonts/open-sans-v17-latin-italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v17-latin-italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v17-latin-italic.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v17-latin-italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v17-latin-italic.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}

/* open-sans-regular - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/open-sans-v17-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Regular'), local('OpenSans-Regular'),
    url('/fonts/open-sans-v17-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v17-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v17-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v17-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v17-latin-regular.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}

/* open-sans-700 - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/open-sans-v17-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold'), local('OpenSans-Bold'),
    url('/fonts/open-sans-v17-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v17-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v17-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v17-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v17-latin-700.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}

/* open-sans-700italic - latin */
@font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 700;
    src: url('/fonts/open-sans-v17-latin-700italic.eot'); /* IE9 Compat Modes */
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'),
    url('/fonts/open-sans-v17-latin-700italic.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/open-sans-v17-latin-700italic.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/open-sans-v17-latin-700italic.woff') format('woff'), /* Modern Browsers */
    url('/fonts/open-sans-v17-latin-700italic.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/open-sans-v17-latin-700italic.svg#OpenSans') format('svg'); /* Legacy iOS */
    font-display: swap;
}


/* lato-regular - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    src: url('/fonts/lato-v16-latin-regular.eot'); /* IE9 Compat Modes */
    src: local('Lato Regular'), local('Lato-Regular'),
    url('/fonts/lato-v16-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/lato-v16-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/lato-v16-latin-regular.woff') format('woff'), /* Modern Browsers */
    url('/fonts/lato-v16-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/lato-v16-latin-regular.svg#Lato') format('svg'); /* Legacy iOS */
}

/* lato-700 - latin */
@font-face {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    src: url('/fonts/lato-v16-latin-700.eot'); /* IE9 Compat Modes */
    src: local('Lato Bold'), local('Lato-Bold'),
    url('/fonts/lato-v16-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
    url('/fonts/lato-v16-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
    url('/fonts/lato-v16-latin-700.woff') format('woff'), /* Modern Browsers */
    url('/fonts/lato-v16-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
    url('/fonts/lato-v16-latin-700.svg#Lato') format('svg'); /* Legacy iOS */
}
