.searchresult {
    border-top: 3px solid $blue;
    background-color: #EEEEEE;
    padding: 10px 20px;
    margin: 0 0 20px;
}
.searchresult:hover { background-color: #DDDDDD; }

.searchresult span { font-size: 14px; font-weight: bold; }
.searchresult .logo { float: right; height: 85px; padding: 0 0 5px 5px; }
.searchresult .logo img { max-height: 85px; }

.keywords { float: right; height: 85px; width: 200px; }

#map {
    border-radius: 10px;
    border: 3px solid $blue;
    width: 100%;
    height: 700px;
    /*background-image: url(/images/maps.jpg);
    background-position: top right;*/
}

#pagination { color: transparent; }
.pagination { border: medium none; }
.pagination > li > a.page-link, .pagination > li > span.page-link {
    background-color: transparent;
    padding: 6px 6px 3px;
    margin: 3px;
    display: inline-block;
    text-align: center;
    border: medium none;
    border-bottom: 3px solid $blue;
    text-decoration: none;
}
.pagination > li > a.page-link:hover {
    background-color: #eeeeee;
    text-decoration: none;
}

.pagination > li.active > a.page-link,
.pagination > li.active > span.page-link {
    color: #004785;
}

@media (max-width: 750px) {

}
