@import '../../node_modules/bootstrap/dist/css/bootstrap.css';
@import '~font-awesome';
@import 'fonts';

// Variables
$blue: #3366CC;

body, html {
  margin: 0; padding: 0; height: 100%;
}

body.no-webp {
    background-image: url("/images/background.png");
}
body.webp {
    background-image: url("/images/background.webp");
}

body {
  background-position: center top;
  background-repeat: no-repeat;
  background-attachment: fixed;
    background-color: #ECECEC;
}

.wrapper { padding-bottom: 200px; min-height: 100%; position:relative; }
.text-bold { font-weight: bold; }
.btn-default { font-family: 'Open Sans', sans-serif; font-weight: 700; }
.btn-blue { background-color: $blue; color: #ffffff; font-weight: bold; }

.container { width: 890px; }

.blue { color: $blue; }

@import "header";

@import "home";
@import "search";
@import "details";

@import "footer";

#wrapper {
  min-height:100%;
  position:relative;
}


.category-list {
  margin-top: 25px;
  font-family: 'Lato', sans-serif;
}

.category-list ul {
  list-style: none;
}
.category-list a { color: #000000; }
.category-list ul li {
  padding-left: 1em;
  text-indent: -.7em;
}
.category-list ul li:before {
  content: "\002022 ";
  padding-right: 10px;
  color: $blue;
}
.category-header {
  font-family: 'Lato', sans-serif;
}

.information .page-header { margin-bottom: 20px; }
.information p {
  margin: 20px 0;
}
.information {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  line-height: 26px;
}

.abc { background-color: #000000; letter-spacing: 9px; text-align: center; }
.abc a { line-height: 36px; color: #3B3737; font-family: 'Lato', sans-serif; font-weight: 700; }
.abc a:hover { text-decoration: none; }

.flashmessage {
    color: #fff;
    padding: 10px 20px;
    font-weight: bold;
    margin-bottom: 2rem;
}

.notify_success {
    background-color: $blue;
}
.notify_failure {
    background-color: #b91c1c;
}
.has-errors {
    color: #b91c1c;
}

#map {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
}

.details-map #map {
    align-items: center;
}

#map a {
    color: $blue;
    text-decoration: none;
    font-weight: bold;
    font-size: 24px;
}
#map span a {
    color: inherit;
    font-weight: normal;
    font-size: inherit;
    text-decoration: underline;
}

.hideGoogleMaps {
    display: none;
}

.hideGoogleMaps a {
    font-size: 95%;
    color: #333333;
}

@import 'statusbar';

.feature-img {
    background-repeat: no-repeat;
    background-size: cover;
}

/* CH */
.no-webp .feature-1 {
    background-image: url(/images/ch/feature1.png);
}
.webp .feature-1 {
    background-image: url(/images/ch/feature1.webp);
}

.no-webp .feature-2 {
    background-image: url(/images/ch/feature2.png);
}
.webp .feature-2 {
    background-image: url(/images/ch/feature2.webp);
}

.no-webp .feature-3 {
    background-image: url(/images/ch/feature3.png);
}
.webp .feature-3 {
    background-image: url(/images/ch/feature3.webp);
}

.no-webp .feature-4 {
    background-image: url(/images/ch/feature4.png);
}
.webp .feature-4 {
    background-image: url(/images/ch/feature4.webp);
}

/* DE */
.no-webp .feature-1 {
    background-image: url(/images/de/feature1.png);
}
.webp .feature-1 {
    background-image: url(/images/de/feature1.webp);
}

.no-webp .feature-2 {
    background-image: url(/images/de/feature2.png);
}
.webp .feature-2 {
    background-image: url(/images/de/feature2.webp);
}

.no-webp .feature-3 {
    background-image: url(/images/de/feature3.png);
}
.webp .feature-3 {
    background-image: url(/images/de/feature3.webp);
}

.no-webp .feature-4 {
    background-image: url(/images/de/feature4.png);
}
.webp .feature-4 {
    background-image: url(/images/de/feature4.webp);
}

/* AT */
.no-webp .feature-1 {
    background-image: url(/images/at/feature1.png);
}
.webp .feature-1 {
    background-image: url(/images/at/feature1.webp);
}

.no-webp .feature-2 {
    background-image: url(/images/at/feature2.png);
}
.webp .feature-2 {
    background-image: url(/images/at/feature2.webp);
}

.no-webp .feature-3 {
    background-image: url(/images/at/feature3.png);
}
.webp .feature-3 {
    background-image: url(/images/at/feature3.webp);
}

.no-webp .feature-4 {
    background-image: url(/images/at/feature4.png);
}
.webp .feature-4 {
    background-image: url(/images/at/feature4.webp);
}

/*  */
.search-label { display: none; }

.company-content img {
    max-width: 100%;
    height: auto;
}

.text-black {
    color: #333;
}

.company-whatsapp {
    max-width: 160px;
}
